<template>
  <div>
    <h1>blank page</h1>

    <template v-for="(responseCode, index) in responseCodeList">
      <template>
        <div :key="index">
          <template>
            case {{ responseCode }}: <br />
            <div class="pl-3">
              errorMessage = "{{ index.replaceAll("_", " ") }}" <br />
              fatality = false; <br />
              redirect = false; <br />
              break;<br />
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import { RESPONSE_CODE } from "@/core/services/store/modules/ui.module";

export default {
  name: "blank",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Blank" }]);
    this.$store.dispatch(RESPONSE_CODE);
  },
  data() {
    return {};
  },

  computed: {
    responseCodeList() {
      const respList = this.$store.state.ui.responseCode;

      return respList.responseCodes;
    },
  },
  methods: {},
};
</script>
